import $ from 'jquery'
import * as azureCommon from './modules/azureCommon'
import { getGPUTier } from 'detect-gpu'
import Globe from 'globe.gl'

function initGlobe() {
    let globeElement = document.getElementById('globe')

    interface Office {
        officeName: string
        lat: number
        lng: number
    }
    let gData: Array<Office> = [
        {
            officeName: 'Amsterdam',
            lat: 52.33655267995023,
            lng: 4.885538013548801,
        },
        {
            officeName: 'Brussels',
            lat: 50.8475275291079,
            lng: 4.358547371164672,
        },
        {
            officeName: 'Chicago',
            lat: 41.88241966827605,
            lng: -87.63026098650597,
        },
        {
            officeName: 'Denver',
            lat: 39.7472920160904,
            lng: -104.99747721755875,
        },
        {
            officeName: 'Houston',
            lat: 29.76080266157036,
            lng: -95.36635854232934,
        },
        {
            officeName: 'London',
            lat: 51.51551644130973,
            lng: -0.08433495949351238,
        },
        {
            officeName: 'Los Angeles',
            lat: 34.04870757663245,
            lng: -118.26134082936625,
        },
        {
            officeName: 'Newark',
            lat: 40.73462879544997,
            lng: -74.16606677917548,
        },
        {
            officeName: 'New York',
            lat: 40.76510131523477,
            lng: -73.98318627116467,
        },
        {
            officeName: 'San Francisco',
            lat: 37.79534254134758,
            lng: -122.39735195397608,
        },
        {
            officeName: 'Seoul',
            lat: 37.569909948034216,
            lng: 126.97251053876357,
        },
        {
            officeName: 'Shanghai',
            lat: 31.22874273435746,
            lng: 121.45421957670654,
        },
        {
            officeName: 'Silicon Valley',
            lat: 37.42168282345716,
            lng: -122.14158265228554,
        },
        {
            officeName: 'Washington, D.C.',
            lat: 38.90228417645755,
            lng: -77.02055959654791,
        },
    ]

    let mapMarkerSVG = `<svg viewBox="-4 0 36 36">
    <path fill="#1095c1" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"/>
    <circle fill="black" cx="14" cy="14" r="7"/>
    </svg>`

    let globeInstance = Globe({
        animateIn: false,
    })

    let mapURL =
        window.location.hostname == 'devops.arnoldporter.com'
            ? 'https://devops.arnoldporter.com/images/earth-night.jpg'
            : '//unpkg.com/three-globe/example/img/earth-night.jpg'

    let globeInstanceHandle = globeInstance(globeElement!)
        .globeImageUrl(mapURL)
        .htmlElementsData(gData)
        .htmlElement((d) => {
            const el = document.createElement('div')
            el.innerHTML = mapMarkerSVG
            el.style.width = '15px'

            $(el).attr('data-tooltip', d.officeName)

            el.style['pointer-events'] = 'auto'
            el.style.cursor = 'pointer'
            // el.onclick = () => console.info(d)
            return el
        })

    globeInstanceHandle.controls().autoRotate = true
    globeInstanceHandle.controls().autoRotateSpeed = 0.2

    return globeInstanceHandle
}

function getRedirectUri() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    if (urlParams.has('redirectUri')) {
        return urlParams.get('redirectUri')
    } else {
        return null
    }
}

$(async (ready) => {
    let isSignedIn =
        await azureCommon.handleRedirectPromiseOrAcquireTokenSilent()

    if (isSignedIn) {
        $('#sign-in').hide()
        // $('#dashboard').show()
        $('#sign-out').show()

        let redirectUri = getRedirectUri()

        if (redirectUri) {
            window.location.href = redirectUri
        }
    }

    $('#sign-in').on('click', () => {
        let state = getRedirectUri() || ''
        azureCommon.signIn(state)
    })
    $('#sign-out').on('click', () => {
        azureCommon.signOut()
    })

    $('#dashboard').on('click', async () => {
        window.location.href = '/dashboard'
    })

    let gpuTier = await getGPUTier()
    if (gpuTier.fps && gpuTier.fps! >= 60) {
        initGlobe()
    }
})
